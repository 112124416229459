import type { Router, RouteLocation } from 'vue-router'

const PROXY_FUNCTIONS = ['push', 'replace']

export function useLocaleRouter(): Router {
  const { locale } = useI18n()
  const router = useRouter()
  const localePath = useLocalePath()

  return new Proxy(router, {
    get(target, prop) {
      if (typeof prop === 'string' && PROXY_FUNCTIONS.includes(prop)) {
        return (route: RouteLocation) => Reflect.get(target, prop)(localePath(route, locale.value))
      }
      return Reflect.get(target, prop)
    },
  })
}
