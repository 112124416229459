// https://github.com/nuxt/nuxt/issues/14766
const assets = import.meta.glob<any>('~/assets/{icons,images}/**/*', {
  import: 'default',
})
import.meta.glob<any>('~/assets/styles/**/*.scss', {
  eager: true,
})
export function useAsset(path: ComputedRef<string>): any {
  return computed(() => (path.value.endsWith('.svg') ? defineAsyncComponent(assets[`/assets/${path.value}`]) : assets[`/assets/${path.value}`]))
}
