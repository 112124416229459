<template>
  <button class="btn relative" :class="classes">
    <div
      v-if="props.loading"
      :class="[props.size === 'small' ? 'w-5 h-5' : 'w-6 h-6']"
      class="i-svg-spinners:3-dots-fade absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
    ></div>
    <template v-if="props.icon">
      <svg-icon
        :name="props.icon"
        class="flex-shrink-0"
        :class="[
          `svg-icon-${props.icon}`,
          props.size === 'small' ? 'w-5 h-5' : 'w-6 h-6',
          { 'opacity-0': props.loading },
          { 'flex-0': props.type !== 'icon' },
        ]"
        :color="props.iconColor"
      />
    </template>
    <div v-if="props.type !== 'icon'" class="flex-0" :class="textClasses"><slot>Button</slot></div>
  </button>
</template>

<script setup lang="ts">
interface Props {
  type?: 'primary' | 'secondary' | 'tertiary' | 'default' | 'ghost' | 'text' | 'primary-text' | 'icon' | 'seeker' | 'pro' | 'elite'
  size?: 'mini' | 'small' | 'medium' | 'large'
  icon?: string
  iconPosition?: 'left' | 'right'
  iconColor?: string
  textClass?: string
  block?: boolean
  bordered?: boolean
  loading?: boolean
  truncateText?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  size: 'medium',
  iconPosition: 'left',
  iconColor: 'black',
  block: false,
  bordered: false,
  loading: false,
  truncateText: false,
})
const classes = computed(() => {
  const sizeClasses = {
    mini: 'btn-xs',
    small: 'btn-sm',
    medium: 'btn-md',
    large: 'btn-lg',
  }
  const typeClasses = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    tertiary: 'btn-tertiary',
    default: 'btn-default',
    ghost: 'btn-ghost',
    text: 'btn-text',
    'primary-text': 'btn-primary-text',
    icon: 'btn-icon',
    seeker: 'btn-seeker',
    pro: 'btn-pro',
    elite: 'btn-elite',
  }

  return [
    sizeClasses[props.size],
    typeClasses[props.type],
    props.icon && props.iconPosition === 'right' && 'flex-row-reverse',
    props.block && 'w-full',
    props.bordered && 'border-1!',
  ]
})

const lineClampClasses = computed(() => (props.truncateText ? ['whitespace-normal', 'line-clamp-1'] : ['whitespace-nowrap']))

const textClasses = computed(() => [{ 'opacity-0': props.loading }, lineClampClasses.value, props.textClass])
</script>

<style lang="scss" scoped>
.btn {
  @apply px-4 py-2 text-lg border-0 rounded transition-colors cursor-pointer disabled:cursor-default flex justify-center items-center flex-nowrap gap-2;
}
.btn-xs {
  @apply px-3 py-0.5 text-sm gap-1;
}

.btn-sm {
  @apply px-3 py-1 text-base gap-1;
}
.btn-primary {
  @apply text-white bg-yellow-50 hover:bg-yellow-60 active:bg-yellow-80 disabled:bg-gray-20 disabled:text-gray-60;
}
.btn-secondary {
  @apply text-black-50 bg-white border-1 border-gray-50 active:text-yellow-50 active:border-yellow-50 disabled:text-gray-60 disabled:border-gray-60;
  &.active {
    @apply text-yellow-50 border-yellow-50;
  }
}
.btn-tertiary {
  @apply text-black-50 bg-gray-20  border-gray-50  disabled:text-gray-60 disabled:border-gray-60;
}
.btn-ghost {
  @apply text-black-50 bg-transparent hover:bg-gray-20 active:bg-gray-40 disabled:text-gray-60 disabled:bg-transparent;
}
.btn-text {
  @apply text-black-50 bg-transparent hover:bg-gray-20 active:bg-gray-40 disabled:text-gray-60 disabled:bg-transparent;
  &.active {
    @apply text-yellow-50;
  }
}
.btn-primary-text {
  @apply text-yellow-50 bg-transparent disabled:text-gray-60 disabled:bg-transparent;
}
.btn-icon {
  @apply w-10 h-10 p-2 border-0;
  @apply bg-transparent hover:bg-gray-20 active:bg-gray-40 disabled:text-gray-60 disabled:bg-transparent;
  &.btn-sm {
    @apply w-8 h-8 p-1.5;
  }
}
.btn-seeker {
  @apply text-white bg-yellow-50 disabled:bg-gray-20;
}
.btn-pro {
  @apply text-white bg-pink-50 disabled:bg-pink-20;
}
.btn-elite {
  @apply text-white bg-blue-50 disabled:bg-blue-20;
}
</style>
