export enum GtmEvent {
  ACCOUNT_SIGN_UP_CLICKED = 'Account Sign Up Clicked',
  ACCOUNT_SIGNED_UP = 'Account Signed Up',
  ACCOUNT_LOG_IN_CLICKED = 'Account Log In Clicked',
  ACCOUNT_LOGGED_IN = 'Account Logged In',
  ACCOUNT_LOGGED_OUT = 'Account Logged Out',
  PHONE_VERIFICATION_CLICKED = 'Phone Verification Clicked',
  PHONE_VERIFICATION_COMPLETED = 'Phone Verification Completed',
  CATEGORY_VIEWED = 'Category Viewed',
  PRODUCT_PAGE_VIEWED = 'Product Page Viewed',
  TIME_SELECTION_STARTED = 'Time Selection Started',
  TIME_SELECTION_COMPLETED = 'Time Selection Completed',
  ORDER_PREVIEWED = 'Order Previewed',
  ORDER_CREATED = 'Order Created',
  ORDER_COMPLETED = 'Order Completed(FE)',
  ORDER_CANCEL_CLICKED = 'Order Cancel Clicked',
  ORDER_CANCELLED = 'Order Cancelled',
  ORDER_RESCHEDULED_COMPLETED = 'Order Rescheduled Completed',
  HOME_BANNER_CLICKED = 'Home Banner Clicked',
  SEARCH_QUERY_SENT = 'Search Query Sent',
  REGION_CHANGED = 'Region Changed',
  COLLECTION_SAVED = 'Collection Saved',
  COLLECTION_PAGE_VIEWED = 'Collection Page Viewed',
  PROMO_CODE_CLAIMED = 'Promo Code Claimed',
  PROMO_CODE_USED = 'Promo Code Used',
  REFERRAL_PAGE_VIEWED = 'Referral Page Viewed',
  REFERRAL_BUTTON_CLICKED = 'Referral Button Clicked',
  REFERRAL_CODE_ENTERED = 'Referral Code Entered',
  NEWS_BUTTON_CLICKED = 'News Button Clicked',
  ORDER_REVIEW_SENT = 'Order Review Sent',
  BRANCH_PAGE_VIEWED = 'Branch Page Viewed',
}
