import { convertArrayToKeyedObject, genObjectWithDefaultKey } from '../utils/common'
import { LangEnum } from '../i18n.config'

export const supportedLangs = [
  { name: 'zh-TW', code: 'zh-tw', iso: 'zh-TW', text: '繁體中文', 'iso-abbrev': 'zh', file: 'zh_TW.json' },
  { name: 'zh-CN', code: 'zh-cn', iso: 'zh-CN', text: '简体中文', 'iso-abbrev': 'cn', file: 'zh_CN.json' },
  { name: 'ja-JP', code: 'ja', iso: 'ja-JP', text: '日本語', 'iso-abbrev': 'ja', file: 'ja_JP.json' },
  { name: 'en-US', code: 'en', iso: 'en-US', text: 'English', 'iso-abbrev': 'en', file: 'en.json' },
  // { name: 'th-TH', code: 'th', iso: 'th-TH', text: 'ภาษาไทย', 'iso-abbrev': 'th' },
]

export const supportedLangsByCode = convertArrayToKeyedObject(supportedLangs, 'code')

export const supportedLangsByName = convertArrayToKeyedObject(supportedLangs, 'name')

function genThirdPartyLanguageMap(originMap: Record<string, any>) {
  return genObjectWithDefaultKey(originMap, LangEnum.TW)
}

// locales: https://github.com/date-fns/date-fns/tree/main/src/locale
export const dateFnsLanguageMap = genThirdPartyLanguageMap({
  [LangEnum.TW]: () => import('date-fns/locale/zh-TW'),
  [LangEnum.CN]: () => import('date-fns/locale/zh-CN'),
  [LangEnum.JP]: () => import('date-fns/locale/ja'),
  [LangEnum.ENG]: () => import('date-fns/locale/en-US'),
  [LangEnum.THAI]: () => import('date-fns/locale/th'),
})

// locales: https://github.com/tusen-ai/naive-ui/tree/0278f24ea34df0da0bdfe9a15ca627563c898771/src/locales/common
export const nUILanguageModuleMap = genThirdPartyLanguageMap({
  [LangEnum.TW]: () => import('naive-ui').then(({ zhTW, dateZhTW }) => [zhTW, dateZhTW]),
  [LangEnum.CN]: () => import('naive-ui').then(({ zhCN, dateZhCN }) => [zhCN, dateZhCN]),
  [LangEnum.JP]: () => import('naive-ui').then(({ jaJP, dateJaJP }) => [jaJP, dateJaJP]),
  [LangEnum.ENG]: () => import('naive-ui').then(({ enUS, dateEnUS }) => [enUS, dateEnUS]),
  [LangEnum.THAI]: () => import('naive-ui').then(({ thTH, dateThTH }) => [thTH, dateThTH]),
})

export const zohoLanguageMap = genThirdPartyLanguageMap({
  [LangEnum.TW]: 'zh-TW',
  [LangEnum.CN]: 'zh-CN',
  [LangEnum.JP]: 'ja-JP',
  [LangEnum.ENG]: 'en-US',
  [LangEnum.THAI]: 'th-TH',
})

export const jobVacancyLanguageMap = genThirdPartyLanguageMap({
  [LangEnum.TW]: 'zh-TW',
  [LangEnum.CN]: 'zh-CN',
  [LangEnum.ENG]: 'en',
})

export const faqPageLanguageMap = genThirdPartyLanguageMap({
  [LangEnum.TW]: 'zh-tw',
  [LangEnum.CN]: 'zh',
  [LangEnum.ENG]: 'en',
})
