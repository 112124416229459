import { type GetProductsReqParams } from './product'
import type { FmemberBenefit, FmemberRewardDetail } from '@/types/api'

export enum Gender {
  UNSPECIFIED = -1,
  FEMALE,
  MALE,
  OTHER,
}

export enum AuthType {
  EMAIL,
  GOOGLE,
  FACEBOOK,
  GUEST,
  LINE,
}

export enum MembershipLevel {
  SEEKER,
  PRO,
  ELITE,
}

export type Member = {
  account: string
  auth_type: AuthType
  bhd_editable: number
  birthday: string
  bonus: number
  bonus_valid_date: string
  company_name: string
  currency: number
  email: string
  gender: Gender
  invite_code: string
  name: string
  nickname: string
  phone: string
  receipt_company: string
  tax_id: string
  uid: number
  fc_restore_id: string
  benefit: FmemberBenefit
}

export enum MemberForm {
  NAME = 'name',
  EMAIL = 'email',
  SEX = 'sex',
  BIRTHDAY = 'birthday',
}

export type MemberFormItem = {
  title: keyof Member
  titleText: string
  id: string
  placeholder?: string
  memberComponent: Component
  note?: string
  isEditable?: boolean
  isCopyable?: boolean
  attributes?: Record<string, unknown>
}

export const genderI18nKeyMap = {
  [Gender.UNSPECIFIED]: 'all_not_filled',
  [Gender.FEMALE]: 'gender_option_female',
  [Gender.MALE]: 'gender_option_male',
  [Gender.OTHER]: 'gender_option_non-binary',
}

export enum MemberEditStatus {
  UN_EDITED,
  EDITING,
  OTHER_FIELD_EDITING,
}

export interface GetCollectionsReqParams
  extends Pick<
    Partial<GetProductsReqParams>,
    | 'category_filter_ids'
    | 'currency_id'
    | 'starttime'
    | 'endtime'
    | 'filter_regionid'
    | 'filter_time'
    | 'max_price'
    | 'min_price'
    | 'max_branch_rating'
    | 'min_branch_rating'
    | 'size'
    | 'time_range_types'
  > {
  next_page_token?: string
}

export interface GetFavoritesReqParams extends GetCollectionsReqParams, Pick<Partial<GetProductsReqParams>, 'start' | 'lat' | 'lng'> {
  next_page_token?: string
}

type AddFavoriteAction = {
  type: 'addFavorite'
  payload: { id: number; type: 'product' | 'branch' }
}

export type LoginPendingAction = AddFavoriteAction

export enum ActionTypeEnum {
  NONE,
  API,
  IN_APP,
  LINK,
}

export interface MembershipLevelComparison extends Omit<FmemberRewardDetail, 'level'> {
  level?: MembershipLevel
}

export type Reward = {
  isLocked: boolean
  level: MembershipLevel
  levelName: 'SEEKER' | 'PRO' | 'ELITE'
  lowerCaseLevelName: 'seeker' | 'pro' | 'elite'
  class: string
  description: string
  actionType: ActionTypeEnum
  actionText: string
  disabledActionText: string
  actionEndpoint: string
  isClaimed: boolean
}

export type IntroduceSectionContentList = {
  id?: number
  image: string
  title: string
  description: string
  actionType: ActionTypeEnum
  actionText?: string
  actionEndpoint?: string
  rewards?: Reward[]
}
