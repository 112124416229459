import type { $Fetch } from 'nitropack'
import type { FetchOptions } from 'ofetch'
import { useAlertStore } from '@/store/alert'

import { DEFAULT_LANG } from '@/i18n.config'
import type { ApiResponseType } from '@/types/service'
import { checkRequestIsValidIfNeeded } from '@/utils/request'

const isDev = process.env.NODE_ENV === 'development'

export const createApiClient = (options?: FetchOptions) => {
  const config = useRuntimeConfig()
  const paymentApiList = [
    '/v2/niceday/payment/none',
    '/v2/niceday/payment/pay-by-line',
    '/v2/niceday/payment/pay-by-credit-installment',
    '/v2/niceday/payment/pay-by-credit',
  ]
  const skipGeneralAlertList = [...paymentApiList, '/v2/niceday/auth', '/v2/niceday/promo', '/v2/niceday/register', 'v2/niceday/login']
  const baseURL = isDev ? '/api/proxy' : config.public.apiBaseUrl

  return $fetch.create({
    baseURL: baseURL,
    credentials: 'include',
    onRequest({ options, request }) {
      if (!checkRequestIsValidIfNeeded(request)) {
        throw new Error(`Invalid request, error: ${request}`)
      }

      /** 為了取得最新值，須在 `onRequest` 中調用 `useNuxtApp`。 */
      const { localeProperties } = useNuxtApp().$i18n
      const acceptLang = localeProperties.value.name || DEFAULT_LANG
      options.headers = new Headers(options.headers)
      options.headers.set('Accept-Language', acceptLang)
      if (import.meta.client) {
        options.headers.set('Accept-Timezone', getTimeZone())
      } else {
        options.headers.set('Server-Side-Request', 'true')
      }
    },
    // onRequestError({ request, options, error }) {},
    // onResponse({ request, options, response }) {},
    onResponseError(context) {
      // FIXME: 過濾掉檢查登入時的錯誤訊息，但應該有更乾淨的做法
      if (context.response._data?.message && !skipGeneralAlertList.some(path => context.response.url.includes(path))) {
        useAlertStore().error(context.response._data.message)
      }
    },
    ...options,
  })
}

let client: ReturnType<typeof createApiClient>

const useApiClient = <T>(): $Fetch<ApiResponseType<T>> => {
  if (!client) {
    client = createApiClient()
  }
  return client
}

export default useApiClient
