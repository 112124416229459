import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/store/auth'

export default defineNuxtRouteMiddleware(async () => {
  if (process.server) return

  const authStore = useAuthStore()
  const { isAlreadyGetMember } = storeToRefs(authStore)

  if (!isAlreadyGetMember.value) {
    await authStore.getAuth()
    isAlreadyGetMember.value = true
  }
})
