import type { FetchOptions } from 'ofetch'
import { defineStore } from 'pinia'
import { getRegionList, getRegion, getRegionPopular, getRegionDetect } from '@/service/region'

import type { RegionRegionDetectInfo, NicedayRegionRegionInfoRes, SregionRegionsListInfo } from '@/types/api'
import { RegionIdMap } from '@/types/region'

export const useRegionStore = defineStore('region', () => {
  const regionList = ref<SregionRegionsListInfo[]>([])
  const regionDetectInfo = ref<RegionRegionDetectInfo>()
  const currentRegionId = ref<number | undefined>()
  const regionInfo = ref<NicedayRegionRegionInfoRes | undefined>()

  const region = computed<SregionRegionsListInfo | undefined>(
    () => regionList.value.find(region => region.region_id === currentRegionId.value) || regionList.value[0]
  )
  const regionOptions = computed(() => regionList.value.map(region => ({ label: region.name!, value: region.region_id! })))
  const selectedRegionOption = computed(() => regionOptions.value.find(({ value }) => value === currentRegionId.value))
  const isTWRegion = computed(() => currentRegionId.value === RegionIdMap.TAIWAN)

  const fetchRegionList = async () => {
    const { data } = await getRegionList()
    regionList.value = data
    return data
  }
  const fetchRegion = async (id: number, apiOpts?: FetchOptions) => {
    const { data } = await getRegion(id, apiOpts)
    regionInfo.value = data
    return data
  }

  const fetchRegionPopular = async (id: number) => {
    const { data } = await getRegionPopular(id)
    return data
  }

  const fetchRegionDetect = async () => {
    const { data } = await getRegionDetect()
    regionDetectInfo.value = data
    return data
  }

  return {
    regionList,
    regionDetectInfo,
    region,
    regionInfo,
    regionOptions,
    selectedRegionOption,
    currentRegionId,
    isTWRegion,
    fetchRegionList,
    fetchRegion,
    fetchRegionPopular,
    fetchRegionDetect,
  }
})
