import useApiClient from './api-client'
import { getDeviceId } from '@/utils/common'
import type { AuthUserLogin } from '@/types/api'

export async function login({ email, password, verification_token }: { email: string; password: string; verification_token: string }) {
  const api = useApiClient<AuthUserLogin>()
  const payload = {
    username: email,
    password,
    verification_token,
    os: 'web',
    osVersion: window.navigator.userAgent,
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }
  const res = await api('/login', {
    method: 'POST',
    body: payload,
  })

  return res
}
export async function register({ email, password, verification_token }: { email: string; password: string; verification_token: string }) {
  const api = useApiClient()

  const registerData = {
    username: email,
    password,
    verification_token,
    osVersion: window.navigator.userAgent,
    os: 'web',
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }
  const res = await api('/register', {
    method: 'POST',
    body: registerData,
  })

  return res
}

export async function logout() {
  const api = useApiClient()
  const res = await api('/logout', {
    method: 'POST',
  })

  return res
}

export async function forgotPassword({ email, verification_token }: { email: string; verification_token: string }) {
  const api = useApiClient()
  // TODO: 等後端開正確的 api
  const forgotPasswordData = {
    email,
    verification_token,
    osVersion: window.navigator.userAgent,
    os: 'web',
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }
  const res = await api('/password/recover', {
    method: 'POST',
    body: forgotPasswordData,
  })

  return res
}

export async function resetPassword({ password, token }: { password: string; token: string }) {
  const api = useApiClient()
  const forgotPasswordData = {
    password,
    token,
  }
  const res = await api('/password/reset', {
    method: 'POST',
    body: forgotPasswordData,
  })

  return res
}

export async function changePassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
  const api = useApiClient()
  const changePasswordData = {
    old_pwd: oldPassword,
    new_pwd: newPassword,
  }
  const res = await api('/password/change', {
    method: 'POST',
    body: changePasswordData,
  })

  return res
}

export async function facebookLogin(fbToken: string) {
  const api = useApiClient()
  const payload = {
    fbToken,
    os: 'web',
    osVersion: window.navigator.userAgent,
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }
  const res = await api('/login/facebook', {
    method: 'POST',
    body: payload,
  })

  return res
}

export async function facebookRegister(fbToken: string) {
  const api = useApiClient()

  const registerData = {
    fbToken,
    osVersion: window.navigator.userAgent,
    os: 'web',
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }

  const res = await api('/register/facebook', {
    method: 'POST',
    body: registerData,
  })

  return res
}

export async function googleLogin(googleToken: string) {
  const api = useApiClient()
  const payload = {
    access_token: googleToken,
    os: 'web',
    osVersion: window.navigator.userAgent,
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }

  const res = await api('/login/google', {
    method: 'POST',
    body: payload,
  })

  return res
}

export async function googleRegister(googleToken: string) {
  const api = useApiClient()

  const registerData = {
    access_token: googleToken,
    osVersion: window.navigator.userAgent,
    os: 'web',
    appVersion: '1.0',
    deviceId: getDeviceId(),
  }

  const res = await api('/register/google', {
    method: 'POST',
    body: registerData,
  })

  return res
}
