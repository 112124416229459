import { format, addMinutes, parseISO, isValid } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const formatUTC = (rawDate: Date | string, _format = 'yyyy/MM/dd HH:mm') => {
  if (!rawDate) return ''
  try {
    let date = rawDate instanceof Date ? rawDate : parseISO(rawDate)
    date = isValid(date) ? date : new Date(rawDate)
    return format(addMinutes(date, -1 * date.getTimezoneOffset()), _format)
  } catch {
    return rawDate
  }
}

export const formatDate = (rawDate: Date | string, _format = 'yyyy/MM/dd HH:mm') => {
  if (!rawDate) return ''
  try {
    let date = rawDate instanceof Date ? rawDate : parseISO(rawDate)
    date = isValid(date) ? date : new Date(rawDate)
    if (!(date instanceof Date) || isNaN(date.valueOf())) return rawDate
    return format(date, _format)
  } catch {
    return rawDate
  }
}

/**
 * 只處理 hh:mm 的大小比較
 * */
export const isEarlierInHourMinute = (date1: string, date2: string) => {
  if (!date1 || !date2) throw new Error('date string is empty')

  const numDate1 = Number(date1.split(':').join(''))
  const numDate2 = Number(date2.split(':').join(''))

  if (isNaN(numDate1) || isNaN(numDate2)) throw new Error('date string format must be "hh:mm"')

  return numDate1 < numDate2
}

export const isEarlierOrEqualInHourMinute = (date1: string, date2: string) => {
  return date1 === date2 || isEarlierInHourMinute(date1, date2)
}

export const getTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch {
    return ''
  }
}

export function getUtcDate(rawDate: string | Date = new Date(), timezone = getTimeZone()) {
  // 註：rawDate 不能是 ISO string
  // 跨時區問題一率先轉換為 UTC 時間
  // 呈現再透過 formatInTimeZone 轉換成指定時區
  return zonedTimeToUtc(rawDate, timezone)
}
