import { nanoid } from 'nanoid'
export const mapObjectEntries = <T, U>(obj: Record<string, T>, fn: (value: T, key: string) => U): Record<string, U> => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fn(value, key)]))
}

export const chunking = <T>(arr: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size))
}

export const nestedArrToString = (arr: any[]): string => {
  const result = arr
    .reduce((acc, cur) => {
      if (cur === null || cur === undefined) return acc

      switch (typeof cur) {
        case 'object':
          if (Array.isArray(cur)) {
            if (arr.length) acc.push(nestedArrToString(cur))
          } else {
            acc.push(nestedArrToString(Object.values(cur)))
          }
          break

        case 'number':
        case 'boolean':
          acc.push(cur)
          break

        case 'string':
          if (cur.length) acc.push(cur)
          break

        case 'function':
        case 'symbol':
        case 'bigint':
        default:
          if (cur.toString().length) acc.push(cur.toString())
          break
      }

      return acc
    }, [])
    .join(',')

  return `[${result}]`
}

export const cloneDeep = <T>(obj: T): T => {
  const structuredCloneSupported = 'structuredClone' in globalThis
  const rawObject = toRawDeep(obj)

  if (!structuredCloneSupported) return JSON.parse(JSON.stringify(rawObject))
  return structuredClone(rawObject)
}

export const getRandomString = (): string => {
  return Math.random().toString(36).slice(2)
}

export const getDeviceId = (): string => {
  const deviceId = localStorage.getItem('deviceId')
  if (deviceId) {
    return deviceId
  }
  const newDeviceId = nanoid()
  localStorage.setItem('deviceId', newDeviceId)
  return newDeviceId
}

export const generateSearchParams = (params: Record<string, any>) => {
  const cleanedParams = Object.entries(params).reduce(
    (result, [key, value]) => {
      if (value === null || value === undefined || value === '') return result
      result[key] = value
      return result
    },
    {} as Record<string, any>
  )
  const searchParams = new URLSearchParams(cleanedParams)
  // TODO: array qs not support yet
  const rawObject = Object.fromEntries(searchParams.entries())
  return { searchParams, rawObject }
}

export const sleep = (ms: number) => new Promise(resolve => window.setTimeout(resolve, ms))

export const isEmpty = (value: any) => {
  if (Array.isArray(value)) return !value.length
  if (typeof value === 'object') return !Object.keys(value).length
  return !value
}

export const htmlToText = (html: string) => html.replace(/(<([^>]+)>)/gi, '')

export const toRawDeep = <T>(observed: T): T => {
  const val = toRaw(observed)

  if (Array.isArray(val)) {
    return val.map(toRawDeep) as T
  }

  if (val === null) return null as T

  if (typeof val === 'object') {
    const entries = Object.entries(val).map(([key, val]) => [key, toRawDeep(val)])

    return Object.fromEntries(entries)
  }

  return val
}

export const checkWebview = (userAgent: string) => {
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})']
  const regex = new RegExp(`(${rules.join('|')})`, 'ig')

  return Boolean(userAgent.match(regex))
}

export const convertArrayToKeyedObject = <T extends Record<string, any>, K extends keyof T>(array: T[], key: K) => {
  if (!Array.isArray(array)) return {}

  return array.reduce<Record<string, T>>((acc, cur) => {
    if (typeof cur[key] !== 'undefined') {
      acc[cur[key]] = cur
    }

    return acc
  }, {})
}

export const trimNestedArray = (arr: any[]): any[] => {
  if (!Array.isArray(arr) || arr.length === 0) return []

  return arr.reduce((acc, cur) => {
    if (cur === null || cur === undefined) return acc

    if (Array.isArray(cur)) {
      if (arr.length) {
        const trimmed = trimNestedArray(cur)
        if (trimmed.length) acc.push(trimmed)
      }
    } else {
      acc.push(cur)
    }

    return acc
  }, [])
}

export const trimObject = (obj: Record<string, any>): Record<string, any> => {
  if (typeof obj !== 'object' || obj === null) return {}

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value === null || value === undefined) return acc

      if (Array.isArray(value)) {
        const trimmed = trimNestedArray(value)
        if (trimmed.length) acc[key] = trimmed
      } else if (typeof value === 'object') {
        const trimmed = trimObject(value)
        if (Object.keys(trimmed).length) acc[key] = trimmed
      } else {
        acc[key] = value
      }

      return acc
    },
    {} as Record<string, any>
  )
}

export const throwNotFoundError = () => {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
}

export const genObjectWithDefaultKey = <T extends object, K extends keyof T>(originMap: T, defaultKey: K) => {
  return new Proxy(originMap, {
    get(target, prop) {
      return Reflect.get(target, prop) || Reflect.get(target, defaultKey)
    },
  })
}
