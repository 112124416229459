import type { GlobalThemeOverrides } from 'naive-ui'
import { colors } from './theme.config'

const themeOverrides: GlobalThemeOverrides = {
  common: {
    borderRadius: '4px',
    scrollbarBorderRadius: '8px',
    borderRadiusSmall: '2px',
    borderColor: colors.gray['50'],
    primaryColor: colors.yellow['50'],
    primaryColorHover: colors.yellow['60'],
    primaryColorPressed: colors.yellow['80'],
    primaryColorSuppl: colors.yellow['20'],
    textColorBase: colors.black['50'],
    textColorDisabled: colors.gray['60'],
    dividerColor: colors.gray['50'],
    placeholderColor: colors.gray['60'],
    fontSizeMedium: '18px',
    fontSizeSmall: '16px',
    fontSizeTiny: '12px',
    fontSizeLarge: '20px',
    errorColor: colors.red['50'],
    successColor: colors.green['60'],
  },
  Alert: {
    iconMargin: '16px 12px 0px 20px',
    padding: '16px 0px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '4px',
    colorSuccess: colors.green['60'],
    contentTextColorSuccess: colors.white,
    colorError: colors.red['50'],
    contentTextColorError: colors.white,
  },
  Button: {
    colorPrimary: colors.yellow['50'],
    textColorPrimary: colors.yellow['50'],
    rippleColorPrimary: colors.yellow['50'],
    colorDisabledPrimary: colors.gray['10'],
    textColorDisabledPrimary: colors.gray['50'],
  },
  Input: {
    borderError: `1px solid ${colors.red['50']}`,
    borderFocus: `1px solid ${colors.yellow['50']}`,
    boxShadowFocus: '',
    paddingSmall: '6px 12px',
    heightSmall: '36px',
    paddingMedium: '8px 12px',
    heightMedium: '40px',
    clearSize: '24px',
    placeholderColor: colors.gray['60'],
  },
  DatePicker: {
    calendarLeftPaddingDaterange: '0',
    calendarRightPaddingDaterange: '0',
    calendarDividerColor: 'transparent',
    panelHeaderDividerColor: 'transparent',
    itemCellWidth: '36px',
    itemCellHeight: '32px',
    itemBorderRadius: '100%',
    itemTextColorDisabled: colors.gray['60'],
    calendarTitleFontSize: '14px',
    calendarTitleHeight: '28px',
    arrowSize: '16px',
    arrowColor: colors.black['50'],
  },
  Collapse: {
    titleFontSize: '18px',
    fontSize: '18px',
    itemMargin: '0',
    dividerColor: 'transparent',
  },
  Popover: {
    padding: '0',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
  },
  Drawer: {
    bodyPadding: '0',
    headerPadding: '0',
    footerPadding: '0',
    headerBorderBottom: '0',
    footerBorderTop: '0',
  },
  Pagination: {
    itemFontSizeMedium: '14px',
    itemSizeMedium: '24px',
    itemMarginMedium: '0 0 0 8px',
    itemTextColor: colors.black['50'],
    itemTextColorActive: colors.gray['10'],
    itemTextColorHover: colors.gray['10'],
    itemTextColorPressed: colors.gray['10'],
    itemTextColorDisabled: colors.gray['60'],
    itemColorActive: colors.yellow['50'],
    itemColorActiveHover: colors.yellow['60'],
    itemColorHover: colors.yellow['60'],
    itemColorPressed: colors.yellow['80'],
    buttonBorder: 0,
    buttonBorderHover: 0,
    buttonBorderPressed: 0,
    itemBorderDisabled: 0,
  },
  Radio: {
    fontSizeMedium: '16px',
    labelLineHeight: '24px',
    labelPadding: '0 12px',
    boxShadowFocus: `inset 0 0 0 1px ${colors.yellow['50']}`,
  },
  Checkbox: {
    fontSizeMedium: '16px',
    labelLineHeight: '24px',
    labelPadding: '0 12px',
    boxShadowFocus: `inset 0 0 0 1px ${colors.yellow['50']}`,
  },
  Breadcrumb: {
    fontSize: '18px',
    itemLineHeight: '24px',
    itemTextColor: colors.black['50'],
    separatorColor: colors.gray['50'],
  },
}

export default themeOverrides
