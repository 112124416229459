import { defineStore } from 'pinia'
import type { Member } from '@/types/member'
import { getMember as getMemberService, getAuth as getAuthService } from '@/service/member'
import { logout as serviceLogout } from '@/service/login'

import type { AuthStep } from '@/types/auth'
import { AuthType } from '@/types/member'

export const useAuthStore = defineStore('auth', () => {
  const user = ref<Member | null>(null)
  const isAlreadyGetMember = ref(false)

  const isLogin = computed(() => !!user.value?.account || !!user.value?.email)

  const userNickname = computed(() => {
    if (user.value?.nickname) return user.value.nickname

    /**
     * TODO:
     * 在沒有 nickname 時，需依照不同 auth type 切換 nickname 來源；
     * 需要和後端確認 FB/Google 登入時，是否會有 nickname，若無，前端需自行處理
     */
    switch (user.value?.auth_type) {
      case AuthType.FACEBOOK:
      case AuthType.GOOGLE:
      case AuthType.EMAIL:
      default:
        return user.value?.email.split('@')[0]
    }
  })

  const fbAvatar = computed(() => {
    const fbId = user.value?.account
    const isFbLogin = user.value?.auth_type === AuthType.FACEBOOK
    return fbId && isFbLogin ? `https://graph.facebook.com/${fbId}/picture?type=large&width=240&height=240` : ''
  })
  const avatar = computed(() => {
    const DEFAULT_AVATAR = '/images/avatar.png'
    return fbAvatar.value || DEFAULT_AVATAR
  })

  const updateMember = ({ key, value }: { key: keyof Member; value: number | string }) => {
    if (!user.value) return
    user.value = {
      ...user.value,
      [key]: value,
    }
  }

  const getAuth = async () => {
    if (isLogin.value) return
    try {
      const getAuthRes = await getAuthService()
      if (getAuthRes.code !== 0) {
        throw getAuthRes
      }

      return getMember()
    } catch (e) {
      if (process.server) return
      console.error(e)
    }
  }
  const getMember = async () => {
    try {
      const { data } = await getMemberService()
      user.value = data
    } catch (e) {
      if (process.server) return
      console.error(e)
    }
  }

  const logout = async (clear = true) => {
    try {
      await serviceLogout()
      if (clear) {
        user.value = null
      }
    } catch (err) {
      if (err instanceof ErrorEvent) {
        console.error(err.message)
      }
    }
  }

  // Modal
  const isLoginModalShown = ref(false)
  const authStep = ref<AuthStep>('login-menu')

  const openLoginModal = () => {
    isLoginModalShown.value = true
  }

  const closeLoginModal = () => {
    isLoginModalShown.value = false
    authStep.value = 'login-menu'
  }

  const changeAuthStep = (value: AuthStep) => {
    authStep.value = value
  }

  return {
    user,
    isLogin,
    isAlreadyGetMember,
    getAuth,
    getMember,
    logout,
    updateMember,
    userNickname,
    fbAvatar,
    avatar,

    isLoginModalShown,
    authStep,
    openLoginModal,
    closeLoginModal,
    changeAuthStep,
  }
})
