<template>
  <n-config-provider v-bind="langConfig" :theme-overrides="theme" abstract>
    <n-message-provider placement="bottom">
      <NuxtLoadingIndicator :height="4" :color="colors.yellow['50']" />
      <NuxtLayout>
        <NuxtPage />
        <full-page-loading />
        <lang-switcher />
      </NuxtLayout>
    </n-message-provider>
  </n-config-provider>
</template>

<script lang="ts">
import { NConfigProvider, NMessageProvider } from 'naive-ui'

import { colors } from '@/theme.config'
import theme from '@/naive-ui-theme'

import FullPageLoading from '@/components/common/full-page-loading.vue'
import LangSwitcher from '@/components/common/lang-switcher.vue'

import { useNUILocale } from '@/composables/useIntl'

export default defineComponent({
  components: {
    NConfigProvider,
    NMessageProvider,
    FullPageLoading,
    LangSwitcher,
  },
  setup() {
    useNicedaySeoSetting()
    useSentryRegister()

    const { nUILocale, nUIDateLocale } = useNUILocale()
    const langConfig = computed(() => ({ locale: nUILocale.value, dateLocale: nUIDateLocale.value }))

    return {
      colors,
      theme,
      langConfig,
    }
  },
})
</script>

<style lang="scss" src="@/assets/styles/naive-ui-overrides.scss" scoped></style>
<style lang="scss"></style>
