import { defineStore } from 'pinia'

import { useWindowSize } from '@vueuse/core'
import { screens } from '@/theme.config'
import { checkWebview } from '@/utils/common'

export const useAppStore = defineStore('app', () => {
  const { locale: i18nLocale } = useI18n()
  const { coords, locatedAt, resume } = useGeolocation()

  resume()

  const showFullPageLoading = ref(false)
  const showLangSwitcher = ref(false)
  const currency = ref('TWD')
  const locale = computed(() => i18nLocale.value)

  const device = useDevice()
  const { width } = useWindowSize({ initialWidth: device.isMobile ? screens.sm : screens.xl })
  const isWebview = computed(() => checkWebview(device.userAgent))

  const isMobile = computed(() => width.value < screens.sm)
  const isTablet = computed(() => width.value >= screens.sm && width.value < screens.md)
  const isDesktop = computed(() => width.value >= screens.md && width.value < screens.lg)
  const isLargeDesktop = computed(() => width.value >= screens.lg && width.value < screens.xl)
  const isExtraLargeDesktop = computed(() => width.value >= screens.xl)

  const isLargeDesktopScreen = computed(() => isLargeDesktop.value || isExtraLargeDesktop.value)
  const isDesktopScreen = computed(() => isDesktop.value || isLargeDesktopScreen.value)

  const { isOnline, onlineAt, offlineAt, downlink, downlinkMax, type } = useNetwork()

  return {
    showFullPageLoading,
    showLangSwitcher,
    currency,
    locale,
    geolocation: {
      latitude: computed(() => coords.value?.latitude),
      longitude: computed(() => coords.value?.longitude),
      locatedAt,
    },
    isWebview,
    /** window width < 600 */
    isMobile,
    /** window width >= 600 && < 768 */
    isTablet,
    /** window width >= 768 && < 960 */
    isDesktop,
    /** window width >= 960 && < 1280 */
    isLargeDesktop,
    /** window width >= 1280 */
    isExtraLargeDesktop,
    isLargeDesktopScreen,
    isDesktopScreen,

    network: {
      isOnline,
      onlineAt,
      offlineAt,
      downlink,
      downlinkMax,
      type,
    },
  }
})
