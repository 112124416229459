import { defineStore } from 'pinia'
import { type NDAlertType } from '@/components/common/nd-alert.vue'

const RESET_TIME = 5000
const ANIMATION_TIME = 300

export const useAlertStore = defineStore('alert', () => {
  const isAlertShown = ref(false)
  const alertMessage = ref('')
  const alertType = ref<NDAlertType>('success')
  const icon = ref('')
  const { start: startHideAlertTimer } = useTimeoutFn(() => (isAlertShown.value = false), RESET_TIME)
  const { start: startClearAlertTimer } = useTimeoutFn(clear, RESET_TIME + ANIMATION_TIME)

  function success(message: string) {
    alertType.value = 'success'
    alertMessage.value = message
    isAlertShown.value = true
    icon.value = ''
    autoClear()
  }

  function error(message: string) {
    alertType.value = 'error'
    alertMessage.value = message
    isAlertShown.value = true
    icon.value = 'alert'
    autoClear()
  }

  function show({ message, type, iconName }: { message: string; type?: NDAlertType; iconName?: string }) {
    alertType.value = type
    alertMessage.value = message
    isAlertShown.value = true
    icon.value = iconName || ''
    autoClear()
  }

  function autoClear() {
    startHideAlertTimer()
    startClearAlertTimer()
  }

  function clear() {
    alertType.value = 'success'
    alertMessage.value = ''
    isAlertShown.value = false
    icon.value = ''
  }

  return {
    isAlertShown,
    alertMessage,
    alertType,
    icon,
    success,
    error,
    show,
    clear,
  }
})
