<template>
  <NuxtLayout>
    <div id="error-page" class="bg-gray-20">
      <template v-if="is404">
        <div
          class="container mx-auto max-w-840px flex flex-col items-center px-5 py-15 gap-y-5 md:grid md:grid-cols-[1fr_320px] md:grid-rows-2 md:gap-x-8 md:gap-y-6 lg:gap-x-18 lg:px-0"
        >
          <div class="info md:flex md:flex-col md:justify-end md:h-full">
            <div class="text-center md:text-start">
              <h2 class="text-2xl md:text-3xl">{{ $t('error_page_404_title') }}</h2>
              <p class="mt-2 md:text-lg">{{ $t('error_page_404_desc') }}</p>
            </div>
          </div>
          <div class="decoration-image w-80 max-w-100% md:row-span-2">
            <nd-img src="@/assets/images/404.svg" />
          </div>
          <div class="actions h-full md:flex md:items-start">
            <nd-button type="primary" @click="handleRedirectBtnClick">{{ $t('all_back_to_index_btn') }}</nd-button>
          </div>
        </div>
      </template>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
enum ErrorType {
  NOT_FOUND = '404',
  // ServerError = '500',
}

const router = useLocaleRouter()

// TODO: 未來有支援更多種錯誤頁時，要改成從 error 取得
// const error = useError()
// const errorType = computed(() => error.value.statusCode)
const errorType = computed(() => ErrorType.NOT_FOUND)

const is404 = computed(() => errorType.value === ErrorType.NOT_FOUND)

const handleRedirectBtnClick = () => {
  router.replace('/')
}
</script>

<style lang="scss" scoped></style>
