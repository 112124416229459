export enum RegionIdMap {
  Taipei = 1,
  Taichung = 3,
  HONG_KONG = 4,
  OKINAWA = 5,
  TAINAN_KAOHSIUNG = 6,
  TOKYO = 8,
  KualaLumpur = 11,
  Bangkok = 13,
  SAPPORO = 14,
  KANSAI = 15,
  PENANG = 16,
  CHIANGMAI = 20,
  TAIWAN = 21,
  MALAYSIA = 33,
}
