export enum LangEnum {
  TW = 'zh-tw',
  CN = 'zh-cn',
  JP = 'ja',
  ENG = 'en',
  THAI = 'th',
}

export const DEFAULT_LANG = 'zh-TW'

export const HAN_RELATED_LANGS = [LangEnum.TW, LangEnum.CN, LangEnum.JP]

const langId = {
  [LangEnum.TW]: 0,
  [LangEnum.ENG]: 1,
  [LangEnum.CN]: 2,
  [LangEnum.JP]: 3,
  // 4 -> 馬來語
  [LangEnum.THAI]: 5,
}

// no need i18n
export const langOptions = {
  [langId[LangEnum.TW]]: { label: '繁體中文', value: LangEnum.TW, tag: '繁中' },
  [langId[LangEnum.ENG]]: { label: 'English', value: LangEnum.ENG, tag: 'EN' },
  [langId[LangEnum.CN]]: { label: '简体中文', value: LangEnum.CN, tag: '简中' },
  [langId[LangEnum.JP]]: { label: '日本語', value: LangEnum.JP, tag: '日本語' },
  [langId[LangEnum.THAI]]: { label: 'ภาษาไทย', value: LangEnum.THAI, tag: 'ภาษาไทย' },
}
