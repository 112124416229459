// plugins/myPiniaPlugin.ts
import type { PiniaPluginContext } from 'pinia'
import { useCloned } from '@vueuse/core'

/**
 * 由於用 function/setup 寫法定義的 Pinia Store 無法使用 $reset
 * Store "xxx" is build using the setup syntax and does not implement $reset().
 * 因此自定 pinia plugin 添加 $reset 功能
 */
function resetStore({ store }: PiniaPluginContext) {
  const { cloned: initialState } = useCloned(store.$state)
  store.$reset = () => store.$patch(initialState.value)
}

export default defineNuxtPlugin(nuxtApp => {
  ;(nuxtApp.$pinia as any).use(resetStore)
})
