import * as Sentry from '@sentry/vue'
import type { TracingOptions } from '@sentry/vue/types/types'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { configEnv, sentryDsn, version, webBaseUrl, apiBaseUrl, funnowCdnBaseUrl },
  } = useRuntimeConfig()

  const isProd = configEnv === 'prod'

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDsn,
    release: version,
    environment: configEnv,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [webBaseUrl, apiBaseUrl, funnowCdnBaseUrl, /^\//],
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay(),
    ],
    autoSessionTracking: true,
    tracesSampleRate: isProd ? 0.2 : 1.0,
    profilesSampleRate: isProd ? 0.2 : 1.0,
    replaysSessionSampleRate: isProd ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    // debug: !isProd,
    enabled: false,
  })

  const sentryTracingHandlerConfig = {
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  } as TracingOptions

  nuxtApp.vueApp.mixin(Sentry.createTracingMixins(sentryTracingHandlerConfig))
  // unset errorHandler before call sentry `attachErrorHandler`.
  // cause https://github.com/getsentry/sentry-javascript/blob/develop/packages/vue/src/errorhandler.ts#L10
  nuxtApp.vueApp.config.errorHandler = undefined
  Sentry.attachErrorHandler(nuxtApp.vueApp, {
    logErrors: true,
    attachProps: true,
    ...sentryTracingHandlerConfig,
  })

  const provideMethods = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
    withScope: Sentry.withScope,
    setUser: Sentry.setUser,
  }

  return {
    provide: {
      sentry: provideMethods,
    },
  }
})
