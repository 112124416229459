import { useAppStore } from '@/store/app'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('price', {
    mounted(el, binding) {
      const price = binding.value
      const store = useAppStore()

      const locale = computed(() => store.locale)

      el.textContent = priceFormat(price, locale.value)
    },
  })
})
