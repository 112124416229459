import { useAuthStore } from '@/store/auth'
import { QUERY_STRING_KEYS } from '@/constants/query-string-keys'

export default defineNuxtPlugin(nuxtApp => {
  const authStore = useAuthStore()
  const route = useRoute()

  nuxtApp.hook('page:finish', () => {
    if (route.query[QUERY_STRING_KEYS.REDIRECT_URL] && !authStore.isLogin && !authStore.isLoginModalShown) {
      authStore.openLoginModal()
    }
  })
})
