import { type NLocale, type NDateLocale } from 'naive-ui'

import { langOptions } from '@/i18n.config'
import { useRegionStore } from '@/store/region'
import { useAuthStore } from '@/store/auth'
import { nUILanguageModuleMap } from '@/constants/locales'

export const locales = [
  {
    key: 'zh-TW',
    name: '繁體中文 (台灣)',
  },
  {
    key: 'ms-MY',
    name: 'Bahasa Melayu (Malaysia)',
  },
  {
    key: 'ja-JP',
    name: '日本語 (日本)',
  },
  {
    key: 'zh-HK',
    name: '繁體中文 (香港)',
  },
  {
    key: 'th-TH',
    name: 'ภาษาไทย (ประเทศไทย)',
  },
  {
    key: 'en-AU',
    name: 'English (Australia)',
  },
]

export function useRegionNames() {
  const { locale } = useI18n()
  const regionNames = computed(() => new Intl.DisplayNames([locale.value], { type: 'region' }))

  function getRegionName(countryCode = 'TW') {
    try {
      return regionNames.value.of(countryCode) || ''
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  return {
    getRegionName,
  }
}

export function useNUILocale() {
  const { locale } = useI18n()
  const nUILocale = ref<Nullable<NLocale>>(null)
  const nUIDateLocale = ref<Nullable<NDateLocale>>(null)

  watch(locale, updateNUILocale, { immediate: true })

  async function updateNUILocale() {
    try {
      const [commonModule, dateModule] = await nUILanguageModuleMap[locale.value]()

      /**
       * 即時更新會踩到 nuxt 太頻繁更新 UI 的 bug，所以延遲一秒更新，預期 vue 升到 3.4 可以修正。
       * error msg: "TypeError: Cannot read properties of null (reading 'parentNode')"
       */
      setTimeout(() => {
        ;[nUILocale.value, nUIDateLocale.value] = [commonModule, dateModule]
      }, 1000)
    } catch (error: any) {
      console.warn(`[useNUILocale] locale "${locale.value}" update failed, error: ${error.message}`)
    }
  }

  return { nUILocale, nUIDateLocale }
}

export function useLangSelector() {
  const { locale, setLocale } = useI18n()

  const regionStore = useRegionStore()
  const authStore = useAuthStore()

  const allowLangOptions = computed(
    () => regionStore.region?.languages?.filter(lang => typeof lang.lang_id !== 'undefined')?.map(lang => langOptions[lang.lang_id!]) || []
  )
  const selectedLangOption = computed(() => allowLangOptions.value.find(option => option.value === locale.value))

  async function changeLang(lang: string) {
    if (!lang || lang === locale.value) {
      return
    }

    await setLocale(lang)
    regionStore.fetchRegionList()
    if (authStore.isLogin) {
      authStore.getMember()
    }
  }

  return { locale, selectedLangOption, allowLangOptions, changeLang }
}

export function useUpperCase(str: Ref<string>) {
  const { localeProperties } = useI18n()

  const localeUpperCaseString = computed(() => {
    try {
      const defaultLocaleIso = localeProperties.value.iso
      const res = str.value.toLocaleUpperCase(defaultLocaleIso)
      return res
    } catch (error) {
      console.error(error)
      return ''
    }
  })

  return {
    localeUpperCaseString,
  }
}

export function useIntlNumber() {
  const { localeProperties } = useI18n()
  const defaultLocaleIso = computed(() => localeProperties.value.iso)

  function getLocaleNumber(amount = 0, decimalCount = 0, _locale?: string) {
    try {
      return new Intl.NumberFormat(_locale || defaultLocaleIso.value, {
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
      }).format(amount)
    } catch (error) {
      console.error(error)
      return '0'
    }
  }

  return {
    getLocaleNumber,
  }
}
