export function checkRequestIsValidIfNeeded(request: RequestInfo) {
  if (typeof request !== 'string') {
    return true
  }

  /**
   * /product/:pid/info
   * /product/:pid/comments
   * /product/:pid/sale-regions
   * /branch/:bid
   * /branch/:bid/sale-regions
   * /category/:cid
   */
  const urlRegexList = [
    /^\/product\/(?<id>.+)\/info$/,
    /^\/product\/(?<id>.+)\/comments$/,
    /^\/product\/(?<id>.+)\/sale-regions$/,
    /^\/branch\/(?<id>[^/]+)$/,
    /^\/branch\/(?<id>.+)\/sale-regions$/,
    /^\/category\/(?<id>[^/]+)$/,
  ]

  const matched = urlRegexList.reduce<Nullable<RegExpMatchArray>>((acc, regex) => acc || request.match(regex), null)

  if (!matched) {
    return true
  }

  const id = Number(matched.groups?.id)

  return Number.isInteger(id)
}
