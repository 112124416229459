import { getActivePinia } from 'pinia'
import { createGtm, useGtm } from '@gtm-support/vue-gtm'

import { GtmEvent } from '@/types/gtm'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const isProd = config.public.configEnv === 'prod'

  const trackEvent = (data: GtmEvent | Record<string, unknown>) => {
    const store = getActivePinia()
    const gtm = useGtm()
    const eventPayload = typeof data === 'string' ? { event: data } : data
    gtm?.trackEvent({ ...eventPayload, store: store?.state.value })
  }

  nuxtApp.vueApp.use(
    createGtm({
      id: config.public.gtmId,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: !isProd,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
    })
  )

  // setup gtm directive
  nuxtApp.vueApp.directive('gtm', {
    mounted(el, binding) {
      const { arg: domEvent, value: gtmEvent, modifiers } = binding
      const domEventOptions = {
        once: modifiers.once ?? true,
        passive: modifiers.passive,
        capture: modifiers.capture,
      }
      if (domEvent) {
        el.addEventListener(
          domEvent,
          () => {
            trackEvent(gtmEvent)
          },
          domEventOptions
        )
      } else {
        trackEvent(gtmEvent)
      }
    },
  })

  if (process.client) {
    // setup gtm dataLayer
    window.dataLayer ||= []

    // expose GtmEvent to window
    window.NdGtmEvt = GtmEvent
  }

  // provide global trackEvent function
  return {
    provide: {
      trackEvent,
    },
  }
})
