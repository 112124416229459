import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import app_init_client_vBzS2KSHpS from "/app/src/plugins/app-init.client.ts";
import directives_zayG7cuA64 from "/app/src/plugins/directives.ts";
import gtm_JE2y3coDIz from "/app/src/plugins/gtm.ts";
import naive_ui_tKb6Ec2ET9 from "/app/src/plugins/naive-ui.ts";
import pinia_cfuRVwzvtd from "/app/src/plugins/pinia.ts";
import sentry_client_KAXFuL2wum from "/app/src/plugins/sentry.client.ts";
import swiper_client_uDPQhOjmYM from "/app/src/plugins/swiper.client.ts";
import vue3_google_login_1Oijq1G58P from "/app/src/plugins/vue3-google-login.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  unocss_MzCDxu9LMj,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  app_init_client_vBzS2KSHpS,
  directives_zayG7cuA64,
  gtm_JE2y3coDIz,
  naive_ui_tKb6Ec2ET9,
  pinia_cfuRVwzvtd,
  sentry_client_KAXFuL2wum,
  swiper_client_uDPQhOjmYM,
  vue3_google_login_1Oijq1G58P
]