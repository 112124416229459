import type { RouterConfig } from '@nuxt/schema'

export default {
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()

    return new Promise(resolve => {
      nuxtApp.hook('page:finish', () => {
        if (savedPosition) return resolve(savedPosition)

        if (to.hash && to.path === from.path) {
          const el = document?.querySelector(to.hash) as HTMLElement
          if (el?.offsetTop) return resolve({ top: el.offsetTop, left: 0, behavior: 'auto' })
        } else if (to.hash) {
          return resolve({ el: to.hash })
        }

        resolve({
          top: 0,
          left: 0,
        })
      })
    })
  },
} as RouterConfig
