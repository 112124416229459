import type { FetchOptions } from 'ofetch'

import useApiClient from './api-client'
import type { SregionRegionsListInfo, NicedayRegionRegionInfoRes, NicedayRegionPopularData, RegionRegionDetectInfo } from '@/types/api'

export const getRegionList = async () => {
  const api = useApiClient<SregionRegionsListInfo[]>()
  const result = await api('/region')
  return result
}

export const getRegion = async (id: number, args: FetchOptions = {}) => {
  const api = useApiClient<NicedayRegionRegionInfoRes>()
  const result = await api(`/region/${id}`, { ...args, method: 'GET' })
  return result
}

export const getRegionPopular = async (id: number) => {
  const api = useApiClient<NicedayRegionPopularData[]>()
  const result = await api(`/region/${id}/popular`)
  return result
}

export const getRegionDetect = async () => {
  const api = useApiClient<RegionRegionDetectInfo>()
  const xForwardedForIp = useRequestHeaders(['x-forwarded-for'])['x-forwarded-for']
  const [ip] = xForwardedForIp?.split(', ') || []
  const query = { time_zone: getTimeZone(), ip }
  const result = await api(`/region-detect`, { query })
  return result
}
