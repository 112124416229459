import type { FetchOptions } from 'ofetch'

import useApiClient from './api-client'
import type {
  FmemberReqSMSRequest,
  FmemberReqSMSVerify,
  FpromoListData,
  FpromoCodeData,
  MemberListCollectionData,
  NicedayMemberListFavoriteData,
  FmemberExclusivesDetail,
  FmemberFunCoinDetail,
  FpromoRewardPromoInfo,
} from '@/types/api'
import type { Member, GetCollectionsReqParams, GetFavoritesReqParams, MembershipLevelComparison } from '@/types/member'
// import { VerifyPromoCodePayload } from '@/view-models/member'

// 等後端做完後補上 type 和放到 member-singlefield-form 裡面

export async function getAuth() {
  const api = useApiClient()
  const result = await api('/auth')
  return result
}

export async function getMember() {
  const api = useApiClient<Member>()
  const result = await api('/member')
  return result
}

export const patchMember = async (payload: any) => {
  const api = useApiClient<FmemberReqSMSRequest>()
  const result = await api('/member', {
    method: 'PATCH',
    body: payload,
  })
  return result
}

export async function updateMemberRegion(regionId: string | number): Promise<void> {
  const api = useApiClient()

  try {
    await api(`/member/region/${regionId}`, {
      method: 'POST',
    })
  } catch (err) {
    console.error(err)
  }
}

export const postSmsRequest = async (payload: FmemberReqSMSRequest) => {
  const api = useApiClient<FmemberReqSMSRequest>()
  const result = await api('/member/smsRequest', {
    method: 'POST',
    body: payload,
  })
  return result
}

export const postSmsVerify = async (payload: FmemberReqSMSVerify) => {
  const api = useApiClient<FmemberReqSMSVerify>()
  const result = await api('/member/smsVerify', {
    method: 'POST',
    body: payload,
  })
  return result
}

export const getMemberExclusives = async () => {
  const api = useApiClient<FmemberExclusivesDetail>()
  const result = await api('/member/exclusives')

  return result
}
export const getMembershipLevelComparison = async () => {
  const api = useApiClient<MembershipLevelComparison[]>()
  const result = await api('/member/reward-comparison')

  return result
}
export const claimPromoCodeReward = async (path: string) => {
  const api = useApiClient<FpromoRewardPromoInfo>()
  const result = await api(`/${path}`, {
    method: 'POST',
  })

  return result
}

export const getFunCoinsInfo = async (params: { currency_id: number }) => {
  const api = useApiClient<FmemberFunCoinDetail>()
  const result = await api('/member/funcoin', { query: params })

  return result
}

export const getPromos = async () => {
  const api = useApiClient<FpromoListData>()
  const result = await api('/promo')

  return result
}

export const receivePromoCodes = async (params: { promo_code: string }) => {
  const api = useApiClient<FpromoCodeData>()
  const result = await api('/promo', {
    method: 'POST',
    body: params,
  })

  return result
}

export async function getCollections(params: GetCollectionsReqParams) {
  const api = useApiClient<MemberListCollectionData[]>()
  const result = await api('/member/collection', { query: params })

  return result
}

export async function getFavorites(params: GetFavoritesReqParams) {
  const api = useApiClient<NicedayMemberListFavoriteData[]>()
  const result = await api('/member/favorite', { query: params })

  return result
}

export const putFavoriteById = async (id: number, args: FetchOptions = {}) => {
  const api = useApiClient()
  const result = await api(`/member/favorite/${id}`, {
    ...args,
    method: 'PUT',
  })
  return result
}

export const deleteFavoriteById = async (id: number, args: FetchOptions = {}) => {
  const api = useApiClient()
  const result = await api(`/member/favorite/${id}`, {
    ...args,
    method: 'DELETE',
  })
  return result
}

export const putCollectionById = async (id: number, args: FetchOptions = {}) => {
  const api = useApiClient()
  const result = await api(`/member/collection/${id}`, {
    ...args,
    method: 'PUT',
  })
  return result
}

export const deleteCollectionById = async (id: number, args: FetchOptions = {}) => {
  const api = useApiClient()
  const result = await api(`/member/collection/${id}`, {
    ...args,
    method: 'DELETE',
  })
  return result
}
