<template>
  <component
    :is="switcherComponent"
    v-model:show="appStore.showLangSwitcher"
    width="100%"
    placement="right"
    :z-index="zIndex.drawer"
    :title="$t('index_display_language_title')"
  >
    <div class="close-btn flex justify-end py-2 px-3 md:hidden">
      <nd-img src="@/assets/icons/cancel.svg" class="w-10 h-10 p-2 ml-auto cursor-pointer" @click="closeMenu" />
    </div>
    <div class="py-2 md:py-5">
      <div
        v-for="langOption in allowLangOptions"
        :key="langOption.value"
        class="w-full justify-left text-base cursor-pointer px-5 py-3 md:py-4"
        :class="{ 'text-yellow-50': langOption.value === locale }"
        @click="handleLangLabelClick(langOption.value)"
      >
        {{ langOption.label }}
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'

import { zIndex } from '@/theme.config'

const NdModal = defineAsyncComponent(() => import('./nd-modal.vue'))
const NdDrawer = defineAsyncComponent(() => import('./nd-drawer.vue'))

const appStore = useAppStore()
const regionStore = useRegionStore()

const { locale, allowLangOptions, changeLang } = useLangSelector()

const switcherComponent = computed(() => (appStore.isDesktopScreen ? NdModal : NdDrawer))

function closeMenu() {
  appStore.showLangSwitcher = false
}

function handleLangLabelClick(lang: string) {
  changeLang(lang)
  closeMenu()
}

function changeLangIfNeeded(newAllowLangOpts: typeof allowLangOptions.value) {
  if (newAllowLangOpts.length === 0) {
    return
  }

  const isCurrentLangValid = newAllowLangOpts.some(option => option.value === locale.value)

  if (isCurrentLangValid) {
    return
  }

  // 當前語言不支援，則開啟 switcher 讓使用者選擇。
  appStore.showLangSwitcher = true
}

watch(allowLangOptions, changeLangIfNeeded)

/**
 * 當 `locale` 改變，並且 `appStore.showLangSwitcher` 為 `true` 時代表是更改 region 且當前語言不在該 region 支援的語言中，
 * 此情況下的 `locale` 變動需要手動 fetch region list。
 */
watch(locale, () => {
  if (appStore.showLangSwitcher) {
    regionStore.fetchRegionList()
  }
})
</script>
