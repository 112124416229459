import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as aboutv3Wuob7z2aMeta } from "/app/src/pages/about.vue?macro=true";
import { default as fb_45loginAFtsmR7SxeMeta } from "/app/src/pages/auth/fb-login.vue?macro=true";
import { default as reset_45passwordDYGzlXgDJjMeta } from "/app/src/pages/auth/reset-password.vue?macro=true";
import { default as indexfiLG1yogjCMeta } from "/app/src/pages/booking/[pid]/index.vue?macro=true";
import { default as previewrNbkCkpA14Meta } from "/app/src/pages/booking/[pid]/preview.vue?macro=true";
import { default as remarks9AJu0fPszgMeta } from "/app/src/pages/booking/[pid]/remarks.vue?macro=true";
import { default as _91bid_93NZpJrjFQDjMeta } from "/app/src/pages/branches/[bid].vue?macro=true";
import { default as corporationh2K0KxMiLqMeta } from "/app/src/pages/corporation.vue?macro=true";
import { default as account_45settingsrNgZjEFJ4SMeta } from "/app/src/pages/member/account-settings.vue?macro=true";
import { default as couponskIYfBRfwQwMeta } from "/app/src/pages/member/coupons.vue?macro=true";
import { default as favoriteswBi0mDbwHdMeta } from "/app/src/pages/member/favorites.vue?macro=true";
import { default as fun_45coinquO3N7FyhBMeta } from "/app/src/pages/member/fun-coin.vue?macro=true";
import { default as funmiles_45rewardsFckdriMDHgMeta } from "/app/src/pages/member/funmiles-rewards.vue?macro=true";
import { default as funmilesPVTreAjxguMeta } from "/app/src/pages/member/funmiles.vue?macro=true";
import { default as ordersQ7ZseikkSvMeta } from "/app/src/pages/member/orders.vue?macro=true";
import { default as personal_45info3V8sI5r1O8Meta } from "/app/src/pages/member/personal-info.vue?macro=true";
import { default as _91oid_93iejXwSbRMJMeta } from "/app/src/pages/orders/[oid].vue?macro=true";
import { default as confirmationrdLAvv4EHXMeta } from "/app/src/pages/payment/confirmation.vue?macro=true";
import { default as policyIAFIBEaui8Meta } from "/app/src/pages/policy.vue?macro=true";
import { default as privacy3wcvBua6OkMeta } from "/app/src/pages/privacy.vue?macro=true";
import { default as _91pid_93xedoKZD35VMeta } from "/app/src/pages/products/[pid].vue?macro=true";
import { default as _91bid_93KG6RE1tT4NMeta } from "/app/src/pages/regions/[rid]/branches/[bid].vue?macro=true";
import { default as _91cid_93VVrvTphCdzMeta } from "/app/src/pages/regions/[rid]/categories/[cid].vue?macro=true";
import { default as indexQeEnYFohf9Meta } from "/app/src/pages/regions/[rid]/index.vue?macro=true";
import { default as _91pid_93Sb9EvUmAgIMeta } from "/app/src/pages/regions/[rid]/products/[pid].vue?macro=true";
import { default as searchv0dA8FKdQBMeta } from "/app/src/pages/regions/[rid]/search.vue?macro=true";
import { default as _91tid_93SBup5M6h8UMeta } from "/app/src/pages/regions/[rid]/themes/[tid].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "about___zh-tw",
    path: "/zh-tw/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___zh-cn",
    path: "/zh-cn/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ja",
    path: "/ja/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: aboutv3Wuob7z2aMeta || {},
    component: () => import("/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-fb-login___zh-tw",
    path: "/zh-tw/auth/fb-login",
    component: () => import("/app/src/pages/auth/fb-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-fb-login___zh-cn",
    path: "/zh-cn/auth/fb-login",
    component: () => import("/app/src/pages/auth/fb-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-fb-login___ja",
    path: "/ja/auth/fb-login",
    component: () => import("/app/src/pages/auth/fb-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-fb-login___en",
    path: "/en/auth/fb-login",
    component: () => import("/app/src/pages/auth/fb-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___zh-tw",
    path: "/zh-tw/auth/reset-password",
    meta: reset_45passwordDYGzlXgDJjMeta || {},
    component: () => import("/app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___zh-cn",
    path: "/zh-cn/auth/reset-password",
    meta: reset_45passwordDYGzlXgDJjMeta || {},
    component: () => import("/app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___ja",
    path: "/ja/auth/reset-password",
    meta: reset_45passwordDYGzlXgDJjMeta || {},
    component: () => import("/app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: reset_45passwordDYGzlXgDJjMeta || {},
    component: () => import("/app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid___zh-tw",
    path: "/zh-tw/booking/:pid()",
    component: () => import("/app/src/pages/booking/[pid]/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid___zh-cn",
    path: "/zh-cn/booking/:pid()",
    component: () => import("/app/src/pages/booking/[pid]/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid___ja",
    path: "/ja/booking/:pid()",
    component: () => import("/app/src/pages/booking/[pid]/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid___en",
    path: "/en/booking/:pid()",
    component: () => import("/app/src/pages/booking/[pid]/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-preview___zh-tw",
    path: "/zh-tw/booking/:pid()/preview",
    meta: previewrNbkCkpA14Meta || {},
    component: () => import("/app/src/pages/booking/[pid]/preview.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-preview___zh-cn",
    path: "/zh-cn/booking/:pid()/preview",
    meta: previewrNbkCkpA14Meta || {},
    component: () => import("/app/src/pages/booking/[pid]/preview.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-preview___ja",
    path: "/ja/booking/:pid()/preview",
    meta: previewrNbkCkpA14Meta || {},
    component: () => import("/app/src/pages/booking/[pid]/preview.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-preview___en",
    path: "/en/booking/:pid()/preview",
    meta: previewrNbkCkpA14Meta || {},
    component: () => import("/app/src/pages/booking/[pid]/preview.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-remarks___zh-tw",
    path: "/zh-tw/booking/:pid()/remarks",
    meta: remarks9AJu0fPszgMeta || {},
    component: () => import("/app/src/pages/booking/[pid]/remarks.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-remarks___zh-cn",
    path: "/zh-cn/booking/:pid()/remarks",
    meta: remarks9AJu0fPszgMeta || {},
    component: () => import("/app/src/pages/booking/[pid]/remarks.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-remarks___ja",
    path: "/ja/booking/:pid()/remarks",
    meta: remarks9AJu0fPszgMeta || {},
    component: () => import("/app/src/pages/booking/[pid]/remarks.vue").then(m => m.default || m)
  },
  {
    name: "booking-pid-remarks___en",
    path: "/en/booking/:pid()/remarks",
    meta: remarks9AJu0fPszgMeta || {},
    component: () => import("/app/src/pages/booking/[pid]/remarks.vue").then(m => m.default || m)
  },
  {
    name: "branches-bid___zh-tw",
    path: "/zh-tw/branches/:bid()",
    meta: _91bid_93NZpJrjFQDjMeta || {},
    component: () => import("/app/src/pages/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "branches-bid___zh-cn",
    path: "/zh-cn/branches/:bid()",
    meta: _91bid_93NZpJrjFQDjMeta || {},
    component: () => import("/app/src/pages/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "branches-bid___ja",
    path: "/ja/branches/:bid()",
    meta: _91bid_93NZpJrjFQDjMeta || {},
    component: () => import("/app/src/pages/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "branches-bid___en",
    path: "/en/branches/:bid()",
    meta: _91bid_93NZpJrjFQDjMeta || {},
    component: () => import("/app/src/pages/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "corporation___zh-tw",
    path: "/zh-tw/corporation",
    meta: corporationh2K0KxMiLqMeta || {},
    component: () => import("/app/src/pages/corporation.vue").then(m => m.default || m)
  },
  {
    name: "corporation___zh-cn",
    path: "/zh-cn/corporation",
    meta: corporationh2K0KxMiLqMeta || {},
    component: () => import("/app/src/pages/corporation.vue").then(m => m.default || m)
  },
  {
    name: "corporation___ja",
    path: "/ja/corporation",
    meta: corporationh2K0KxMiLqMeta || {},
    component: () => import("/app/src/pages/corporation.vue").then(m => m.default || m)
  },
  {
    name: "corporation___en",
    path: "/en/corporation",
    meta: corporationh2K0KxMiLqMeta || {},
    component: () => import("/app/src/pages/corporation.vue").then(m => m.default || m)
  },
  {
    name: "index___zh-tw",
    path: "/zh-tw",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh-cn",
    path: "/zh-cn",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "member-account-settings___zh-tw",
    path: "/zh-tw/member/account-settings",
    meta: account_45settingsrNgZjEFJ4SMeta || {},
    component: () => import("/app/src/pages/member/account-settings.vue").then(m => m.default || m)
  },
  {
    name: "member-account-settings___zh-cn",
    path: "/zh-cn/member/account-settings",
    meta: account_45settingsrNgZjEFJ4SMeta || {},
    component: () => import("/app/src/pages/member/account-settings.vue").then(m => m.default || m)
  },
  {
    name: "member-account-settings___ja",
    path: "/ja/member/account-settings",
    meta: account_45settingsrNgZjEFJ4SMeta || {},
    component: () => import("/app/src/pages/member/account-settings.vue").then(m => m.default || m)
  },
  {
    name: "member-account-settings___en",
    path: "/en/member/account-settings",
    meta: account_45settingsrNgZjEFJ4SMeta || {},
    component: () => import("/app/src/pages/member/account-settings.vue").then(m => m.default || m)
  },
  {
    name: "member-coupons___zh-tw",
    path: "/zh-tw/member/coupons",
    meta: couponskIYfBRfwQwMeta || {},
    component: () => import("/app/src/pages/member/coupons.vue").then(m => m.default || m)
  },
  {
    name: "member-coupons___zh-cn",
    path: "/zh-cn/member/coupons",
    meta: couponskIYfBRfwQwMeta || {},
    component: () => import("/app/src/pages/member/coupons.vue").then(m => m.default || m)
  },
  {
    name: "member-coupons___ja",
    path: "/ja/member/coupons",
    meta: couponskIYfBRfwQwMeta || {},
    component: () => import("/app/src/pages/member/coupons.vue").then(m => m.default || m)
  },
  {
    name: "member-coupons___en",
    path: "/en/member/coupons",
    meta: couponskIYfBRfwQwMeta || {},
    component: () => import("/app/src/pages/member/coupons.vue").then(m => m.default || m)
  },
  {
    name: "member-favorites___zh-tw",
    path: "/zh-tw/member/favorites",
    meta: favoriteswBi0mDbwHdMeta || {},
    component: () => import("/app/src/pages/member/favorites.vue").then(m => m.default || m)
  },
  {
    name: "member-favorites___zh-cn",
    path: "/zh-cn/member/favorites",
    meta: favoriteswBi0mDbwHdMeta || {},
    component: () => import("/app/src/pages/member/favorites.vue").then(m => m.default || m)
  },
  {
    name: "member-favorites___ja",
    path: "/ja/member/favorites",
    meta: favoriteswBi0mDbwHdMeta || {},
    component: () => import("/app/src/pages/member/favorites.vue").then(m => m.default || m)
  },
  {
    name: "member-favorites___en",
    path: "/en/member/favorites",
    meta: favoriteswBi0mDbwHdMeta || {},
    component: () => import("/app/src/pages/member/favorites.vue").then(m => m.default || m)
  },
  {
    name: "member-fun-coin___zh-tw",
    path: "/zh-tw/member/fun-coin",
    meta: fun_45coinquO3N7FyhBMeta || {},
    component: () => import("/app/src/pages/member/fun-coin.vue").then(m => m.default || m)
  },
  {
    name: "member-fun-coin___zh-cn",
    path: "/zh-cn/member/fun-coin",
    meta: fun_45coinquO3N7FyhBMeta || {},
    component: () => import("/app/src/pages/member/fun-coin.vue").then(m => m.default || m)
  },
  {
    name: "member-fun-coin___ja",
    path: "/ja/member/fun-coin",
    meta: fun_45coinquO3N7FyhBMeta || {},
    component: () => import("/app/src/pages/member/fun-coin.vue").then(m => m.default || m)
  },
  {
    name: "member-fun-coin___en",
    path: "/en/member/fun-coin",
    meta: fun_45coinquO3N7FyhBMeta || {},
    component: () => import("/app/src/pages/member/fun-coin.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles-rewards___zh-tw",
    path: "/zh-tw/member/funmiles-rewards",
    meta: funmiles_45rewardsFckdriMDHgMeta || {},
    component: () => import("/app/src/pages/member/funmiles-rewards.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles-rewards___zh-cn",
    path: "/zh-cn/member/funmiles-rewards",
    meta: funmiles_45rewardsFckdriMDHgMeta || {},
    component: () => import("/app/src/pages/member/funmiles-rewards.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles-rewards___ja",
    path: "/ja/member/funmiles-rewards",
    meta: funmiles_45rewardsFckdriMDHgMeta || {},
    component: () => import("/app/src/pages/member/funmiles-rewards.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles-rewards___en",
    path: "/en/member/funmiles-rewards",
    meta: funmiles_45rewardsFckdriMDHgMeta || {},
    component: () => import("/app/src/pages/member/funmiles-rewards.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles___zh-tw",
    path: "/zh-tw/member/funmiles",
    meta: funmilesPVTreAjxguMeta || {},
    component: () => import("/app/src/pages/member/funmiles.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles___zh-cn",
    path: "/zh-cn/member/funmiles",
    meta: funmilesPVTreAjxguMeta || {},
    component: () => import("/app/src/pages/member/funmiles.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles___ja",
    path: "/ja/member/funmiles",
    meta: funmilesPVTreAjxguMeta || {},
    component: () => import("/app/src/pages/member/funmiles.vue").then(m => m.default || m)
  },
  {
    name: "member-funmiles___en",
    path: "/en/member/funmiles",
    meta: funmilesPVTreAjxguMeta || {},
    component: () => import("/app/src/pages/member/funmiles.vue").then(m => m.default || m)
  },
  {
    name: "member-orders___zh-tw",
    path: "/zh-tw/member/orders",
    meta: ordersQ7ZseikkSvMeta || {},
    component: () => import("/app/src/pages/member/orders.vue").then(m => m.default || m)
  },
  {
    name: "member-orders___zh-cn",
    path: "/zh-cn/member/orders",
    meta: ordersQ7ZseikkSvMeta || {},
    component: () => import("/app/src/pages/member/orders.vue").then(m => m.default || m)
  },
  {
    name: "member-orders___ja",
    path: "/ja/member/orders",
    meta: ordersQ7ZseikkSvMeta || {},
    component: () => import("/app/src/pages/member/orders.vue").then(m => m.default || m)
  },
  {
    name: "member-orders___en",
    path: "/en/member/orders",
    meta: ordersQ7ZseikkSvMeta || {},
    component: () => import("/app/src/pages/member/orders.vue").then(m => m.default || m)
  },
  {
    name: "member-personal-info___zh-tw",
    path: "/zh-tw/member/personal-info",
    meta: personal_45info3V8sI5r1O8Meta || {},
    component: () => import("/app/src/pages/member/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "member-personal-info___zh-cn",
    path: "/zh-cn/member/personal-info",
    meta: personal_45info3V8sI5r1O8Meta || {},
    component: () => import("/app/src/pages/member/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "member-personal-info___ja",
    path: "/ja/member/personal-info",
    meta: personal_45info3V8sI5r1O8Meta || {},
    component: () => import("/app/src/pages/member/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "member-personal-info___en",
    path: "/en/member/personal-info",
    meta: personal_45info3V8sI5r1O8Meta || {},
    component: () => import("/app/src/pages/member/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "orders-oid___zh-tw",
    path: "/zh-tw/orders/:oid()",
    meta: _91oid_93iejXwSbRMJMeta || {},
    component: () => import("/app/src/pages/orders/[oid].vue").then(m => m.default || m)
  },
  {
    name: "orders-oid___zh-cn",
    path: "/zh-cn/orders/:oid()",
    meta: _91oid_93iejXwSbRMJMeta || {},
    component: () => import("/app/src/pages/orders/[oid].vue").then(m => m.default || m)
  },
  {
    name: "orders-oid___ja",
    path: "/ja/orders/:oid()",
    meta: _91oid_93iejXwSbRMJMeta || {},
    component: () => import("/app/src/pages/orders/[oid].vue").then(m => m.default || m)
  },
  {
    name: "orders-oid___en",
    path: "/en/orders/:oid()",
    meta: _91oid_93iejXwSbRMJMeta || {},
    component: () => import("/app/src/pages/orders/[oid].vue").then(m => m.default || m)
  },
  {
    name: "payment-confirmation___zh-tw",
    path: "/zh-tw/payment/confirmation",
    meta: confirmationrdLAvv4EHXMeta || {},
    component: () => import("/app/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "payment-confirmation___zh-cn",
    path: "/zh-cn/payment/confirmation",
    meta: confirmationrdLAvv4EHXMeta || {},
    component: () => import("/app/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "payment-confirmation___ja",
    path: "/ja/payment/confirmation",
    meta: confirmationrdLAvv4EHXMeta || {},
    component: () => import("/app/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "payment-confirmation___en",
    path: "/en/payment/confirmation",
    meta: confirmationrdLAvv4EHXMeta || {},
    component: () => import("/app/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "policy___zh-tw",
    path: "/zh-tw/policy",
    meta: policyIAFIBEaui8Meta || {},
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___zh-cn",
    path: "/zh-cn/policy",
    meta: policyIAFIBEaui8Meta || {},
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___ja",
    path: "/ja/policy",
    meta: policyIAFIBEaui8Meta || {},
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "policy___en",
    path: "/en/policy",
    meta: policyIAFIBEaui8Meta || {},
    component: () => import("/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___zh-tw",
    path: "/zh-tw/privacy",
    meta: privacy3wcvBua6OkMeta || {},
    component: () => import("/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___zh-cn",
    path: "/zh-cn/privacy",
    meta: privacy3wcvBua6OkMeta || {},
    component: () => import("/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___ja",
    path: "/ja/privacy",
    meta: privacy3wcvBua6OkMeta || {},
    component: () => import("/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: privacy3wcvBua6OkMeta || {},
    component: () => import("/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "products-pid___zh-tw",
    path: "/zh-tw/products/:pid()",
    meta: _91pid_93xedoKZD35VMeta || {},
    component: () => import("/app/src/pages/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "products-pid___zh-cn",
    path: "/zh-cn/products/:pid()",
    meta: _91pid_93xedoKZD35VMeta || {},
    component: () => import("/app/src/pages/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "products-pid___ja",
    path: "/ja/products/:pid()",
    meta: _91pid_93xedoKZD35VMeta || {},
    component: () => import("/app/src/pages/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "products-pid___en",
    path: "/en/products/:pid()",
    meta: _91pid_93xedoKZD35VMeta || {},
    component: () => import("/app/src/pages/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-branches-bid___zh-tw",
    path: "/zh-tw/regions/:rid()/branches/:bid()",
    meta: _91bid_93KG6RE1tT4NMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-branches-bid___zh-cn",
    path: "/zh-cn/regions/:rid()/branches/:bid()",
    meta: _91bid_93KG6RE1tT4NMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-branches-bid___ja",
    path: "/ja/regions/:rid()/branches/:bid()",
    meta: _91bid_93KG6RE1tT4NMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-branches-bid___en",
    path: "/en/regions/:rid()/branches/:bid()",
    meta: _91bid_93KG6RE1tT4NMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/branches/[bid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-categories-cid___zh-tw",
    path: "/zh-tw/regions/:rid()/categories/:cid()",
    meta: _91cid_93VVrvTphCdzMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/categories/[cid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-categories-cid___zh-cn",
    path: "/zh-cn/regions/:rid()/categories/:cid()",
    meta: _91cid_93VVrvTphCdzMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/categories/[cid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-categories-cid___ja",
    path: "/ja/regions/:rid()/categories/:cid()",
    meta: _91cid_93VVrvTphCdzMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/categories/[cid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-categories-cid___en",
    path: "/en/regions/:rid()/categories/:cid()",
    meta: _91cid_93VVrvTphCdzMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/categories/[cid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid___zh-tw",
    path: "/zh-tw/regions/:rid()",
    meta: indexQeEnYFohf9Meta || {},
    component: () => import("/app/src/pages/regions/[rid]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid___zh-cn",
    path: "/zh-cn/regions/:rid()",
    meta: indexQeEnYFohf9Meta || {},
    component: () => import("/app/src/pages/regions/[rid]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid___ja",
    path: "/ja/regions/:rid()",
    meta: indexQeEnYFohf9Meta || {},
    component: () => import("/app/src/pages/regions/[rid]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid___en",
    path: "/en/regions/:rid()",
    meta: indexQeEnYFohf9Meta || {},
    component: () => import("/app/src/pages/regions/[rid]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-products-pid___zh-tw",
    path: "/zh-tw/regions/:rid()/products/:pid()",
    meta: _91pid_93Sb9EvUmAgIMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-products-pid___zh-cn",
    path: "/zh-cn/regions/:rid()/products/:pid()",
    meta: _91pid_93Sb9EvUmAgIMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-products-pid___ja",
    path: "/ja/regions/:rid()/products/:pid()",
    meta: _91pid_93Sb9EvUmAgIMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-products-pid___en",
    path: "/en/regions/:rid()/products/:pid()",
    meta: _91pid_93Sb9EvUmAgIMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/products/[pid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-search___zh-tw",
    path: "/zh-tw/regions/:rid()/search",
    meta: searchv0dA8FKdQBMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-search___zh-cn",
    path: "/zh-cn/regions/:rid()/search",
    meta: searchv0dA8FKdQBMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-search___ja",
    path: "/ja/regions/:rid()/search",
    meta: searchv0dA8FKdQBMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-search___en",
    path: "/en/regions/:rid()/search",
    meta: searchv0dA8FKdQBMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-themes-tid___zh-tw",
    path: "/zh-tw/regions/:rid()/themes/:tid()",
    meta: _91tid_93SBup5M6h8UMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/themes/[tid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-themes-tid___zh-cn",
    path: "/zh-cn/regions/:rid()/themes/:tid()",
    meta: _91tid_93SBup5M6h8UMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/themes/[tid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-themes-tid___ja",
    path: "/ja/regions/:rid()/themes/:tid()",
    meta: _91tid_93SBup5M6h8UMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/themes/[tid].vue").then(m => m.default || m)
  },
  {
    name: "regions-rid-themes-tid___en",
    path: "/en/regions/:rid()/themes/:tid()",
    meta: _91tid_93SBup5M6h8UMeta || {},
    component: () => import("/app/src/pages/regions/[rid]/themes/[tid].vue").then(m => m.default || m)
  }
]