<template>
  <div v-show="showFullPageLoading" class="w-full h-full fixed top-0 left-0 z-1">
    <div class="w-full h-full bg-gray-10 opacity-80" />
    <div class="w-8 h-8 i-svg-spinners:3-dots-fade text-yellow-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/store/app'

const { showFullPageLoading } = storeToRefs(useAppStore())
</script>
