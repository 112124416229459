import type { CategoryDetailData, FreviewReviewResponse, ScategorySERP, BranchDetailData } from '@/types/api'
import type { ApiResponseType } from '@/types/service'
import type { UiProductInfo } from '@/types/product'

export type SeoSettingName = 'default' | 'category' | 'product' | 'branch'

type ProductSettingPayload = {
  productInfo: ComputedRef<UiProductInfo | undefined>
  productComments: Ref<ApiResponseType<FreviewReviewResponse> | null>
}

const DEFAULT_SETTING = {
  titleKey: 'index_og_title',
  descriptionKey: 'all_og_desc',
  image: 'https://cdn.myfunnow.com/web/og_images/niceday_ogImage.jpg',
  url: 'https://play.niceday.tw/',
}

export function useNicedaySeoSetting(name?: SeoSettingName, payload?: unknown) {
  switch (name) {
    case 'category':
      return useCategorySetting(payload as Ref<CategoryDetailData>)
    case 'product':
      return useProductSetting(payload as ProductSettingPayload)
    case 'branch':
      return useBranchSetting(payload as Ref<BranchDetailData>)
    default:
      return useBasicSetting()
  }
}

const useBasicSetting = () => {
  const { t } = useI18n()
  const config = useRuntimeConfig()
  const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
  })

  const seoTitlePrefix = getTitlePrefix()
  const seoTitle = t(DEFAULT_SETTING.titleKey)
  const seoDescription = t(DEFAULT_SETTING.descriptionKey)

  useHead({
    htmlAttrs: i18nHead.value.htmlAttrs,
    link: [...(i18nHead.value.link || [])],
    meta: [...(i18nHead.value.meta || [])],
  })
  useSeoMeta({
    title: seoTitlePrefix + seoTitle,
    ogTitle: seoTitle,
    description: seoDescription,
    ogDescription: seoDescription,
    ogImage: DEFAULT_SETTING.image,
    ogUrl: DEFAULT_SETTING.url,
    fbAppId: config.public.fbAppId,
    ogType: 'website',
    twitterCard: 'summary_large_image',
  })
  useOrganizationJsonLd()
  useWebsiteJsonLd()
}

const useCategorySetting = (category: Ref<CategoryDetailData | undefined>) => {
  const { t } = useI18n()
  const route = useRoute()
  const rid = route.params.rid as string
  const cid = (route.params.cid || route.params.tid) as string

  const seoTitlePrefix = getTitlePrefix()
  const seoTitle = computed(() =>
    category.value?.page_title
      ? t('category_og_title', { category_page_title: category.value?.page_title, category_name: category.value?.name })
      : t(DEFAULT_SETTING.titleKey)
  )
  const seoDescription = computed(() => category.value?.meta_desc || t('category_og_desc', { category_name: category.value?.name }))
  const faqs = computed(() => category.value?.faq_list || [])
  useSeoMeta({
    title: () => seoTitlePrefix + seoTitle.value,
    ogTitle: () => seoTitle.value,
    description: () => seoDescription.value,
    ogDescription: () => seoDescription.value,
    ogUrl: () => `${DEFAULT_SETTING.url}regions/${rid}/categories/${cid}`,
  })
  useFaqJsonLd(faqs)
}

const useBranchSetting = (branch: Ref<BranchDetailData>) => {
  const { t } = useI18n()
  const route = useRoute()
  const rid = route.params.rid as string
  const bid = route.params.bid as string

  const seoTitlePrefix = getTitlePrefix()
  const seoTitle = computed(() => (branch.value?.name ? t('branch_title', { branch_name: branch.value.name }) : t(DEFAULT_SETTING.titleKey)))

  useSeoMeta({
    title: () => seoTitlePrefix + seoTitle.value,
    ogTitle: () => seoTitle.value,
    ogUrl: () => `${DEFAULT_SETTING.url}regions/${rid}/branches/${bid}`,
  })
}

const useProductSeoInfo = (productInfo: ProductSettingPayload['productInfo']) => {
  const { t } = useI18n()
  const route = useRoute()
  const config = useRuntimeConfig()
  const DESC_MAX_LENGTH = 150

  const locationTag = computed(() => productInfo.value?.region_tag?.name ?? productInfo.value?.region_tag)

  const title = computed(() =>
    t('product_title', {
      product_name: productInfo.value?.product_name,
      branch_name: productInfo.value?.branch_name,
      location_tag: locationTag.value,
    })
  )
  const ogTitle = computed(() =>
    t('product_og_title', {
      product_name: productInfo.value?.product_name,
      branch_name: productInfo.value?.branch_name,
      location_tag: locationTag.value,
    })
  )
  const seoDesc = computed(() => {
    const desc = productInfo.value?.description ? t('product_og_desc') + productInfo.value.description : t(DEFAULT_SETTING.descriptionKey)

    return desc.length > DESC_MAX_LENGTH ? `${desc.slice(0, DESC_MAX_LENGTH - 3)}...` : desc
  })
  const seoImg = computed(() =>
    productInfo.value?.cover_image ? `${config.public.funnowCdnBaseUrl}${productInfo.value.cover_image}` : DEFAULT_SETTING.image
  )
  const ogUrl = computed(() => DEFAULT_SETTING.url + route.fullPath.replace(/^\//, ''))

  return { title, ogTitle, seoDesc, seoImg, ogUrl }
}

const useProductSetting = (payload: ProductSettingPayload) => {
  const { productInfo } = payload
  const { title, ogTitle, seoDesc, seoImg, ogUrl } = useProductSeoInfo(productInfo)

  if (!productInfo.value) return

  const seoTitlePrefix = getTitlePrefix()

  useSeoMeta({
    title: () => seoTitlePrefix + title.value,
    ogTitle: () => ogTitle.value,
    description: () => seoDesc.value,
    ogDescription: () => seoDesc.value,
    ogImage: () => seoImg.value,
    ogUrl: () => ogUrl.value,
  })
  useProductJsonLd(payload)
}

// JSON-LD components
const useOrganizationJsonLd = () => {
  const { t } = useI18n()

  useJsonld({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Niceday',
    url: DEFAULT_SETTING.url,
    sameAs: ['https://www.facebook.com/niceday.tw/', 'https://www.instagram.com/niceday_kids/'],
    logo: `${DEFAULT_SETTING.url}images/logo.png`,
    image: DEFAULT_SETTING.image,
    description: t(DEFAULT_SETTING.descriptionKey),
    contactPoint: [
      {
        '@type': 'ContactPoint',
        areaServed: 'TW',
        email: 'service@niceday.tw',
        contactType: 'customer service',
        availableLanguage: ['Chinese', 'English'],
      },
    ],
  })
}

const useWebsiteJsonLd = () => {
  const { t } = useI18n()

  useJsonld({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: t(DEFAULT_SETTING.titleKey),
    author: 'Zoek Inc.',
    url: DEFAULT_SETTING.url,
    sameAs: ['https://www.facebook.com/niceday.tw/', 'https://www.instagram.com/niceday_kids/'],
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${DEFAULT_SETTING.url}search?keyword={keyword}`,
      },
      // @ts-ignore-next-line
      'query-input': `required name=keyword`,
    },
  })
}

const useFaqJsonLd = (faqs: MaybeRef<ScategorySERP[]>) => {
  const faqWrapper = ref(faqs)
  useJsonld(() => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqWrapper.value.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: htmlToText(faq.answer || ''),
      },
    })),
  }))
}

const useProductJsonLd = ({ productInfo, productComments }: ProductSettingPayload) => {
  const { seoDesc, seoImg } = useProductSeoInfo(productInfo)

  const reviews = computed(() => {
    const result = productComments.value?.data.comment?.map(comment => {
      const [date] = comment.rate_time?.split(' ') || []

      return {
        '@type': 'Review' as const,
        author: {
          '@type': 'Person' as const,
          name: comment.rated_by,
        },
        datePublished: date,
        reviewBody: comment.rating_description,
        reviewRating: {
          '@type': 'Rating' as const,
          bestRating: '5',
          ratingValue: comment.rating,
          worstRating: '1',
        },
      }
    })

    return result
  })

  useJsonld(() => ({
    '@context': 'https://schema.org',
    '@type': 'Product',
    ...(productComments.value?.data.total_count && productComments.value?.data.avg_rating
      ? {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: productComments.value?.data.avg_rating,
            reviewCount: productComments.value?.data.total_count,
          },
        }
      : {}),
    description: seoDesc.value,
    name: productInfo.value?.product_name,
    image: seoImg.value,
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: productInfo.value?.discount,
      priceCurrency: productInfo.value?.currency_code,
    },
    review: reviews.value,
  }))
}

// utils
const getTitlePrefix = () => {
  const config = useRuntimeConfig()
  return config.public.configEnv === 'prod' ? '' : `(${config.public.configEnv}) `
}
