import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/store/auth'

import type { Member } from '@/types/member'

export function useSentryRegister() {
  const authStore = useAuthStore()

  const sentry = useSentry()

  const { user, userNickname } = storeToRefs(authStore)

  function setSentryUserData(userData: Nullable<Member>) {
    const data = userData
      ? {
          ...(!!userData?.uid && { id: userData.uid }),
          ...(!!userNickname.value && { username: userNickname.value }),
          ...(!!userData?.email && { email: userData.email }),
        }
      : null

    sentry?.setUser(data)
  }

  watch(user, setSentryUserData, { immediate: true })
}
