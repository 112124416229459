<template>
  <div v-if="isSvgComponent" class="nd-img-svg flex items-center justify-center transition-opacity" :class="classList">
    <component v-bind="$attrs" :is="svgComponent" />
  </div>
  <picture v-else-if="imgUrl && !isFailed">
    <source v-if="props.useWebp" :srcset="imgUrl" type="image/webp" />
    <img v-bind="imgProps" ref="imgRef" loading="lazy" @load="showImage" @error="onImgLoadError" />
  </picture>
  <div v-else class="nd-img-placeholder aspect-1.8 overflow-hidden bg-gray-30" :class="classList"></div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

interface Props {
  class?: HTMLAttributes['class']
  src?: string
  alt?: string
  useWebp?: boolean
}
interface Emits {
  (event: 'error'): void
}

const nuxtApp = useNuxtApp()
const { isHydrating } = nuxtApp

const config = useRuntimeConfig()
const attrs = useAttrs()

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const src = toRef(props, 'src')

const isLoaded = ref(false)
const isFailed = ref(false)
const imgRef = ref<Nullable<HTMLImageElement>>(null)

const isPublic = computed(() => !!src.value?.startsWith('/'))

const isAsset = computed(() => {
  if (!src?.value) return false
  return src.value.includes('/assets')
})

const isSvgComponent = computed(() => {
  if (!src?.value) return false
  return isAsset.value && src.value.endsWith('.svg')
})

const imgUrl = computed(() => {
  if (!src?.value) return ''
  if (src.value.startsWith('fn:')) return `${config.public.funnowCdnBaseUrl}${src.value.replace('fn:', '')}`
  if (src.value.startsWith('http')) return src.value
  if (isAsset.value) {
    return src.value.replace(/^[~@]\/assets\//, '')
  }

  return src.value
})

const svgComponent = isSvgComponent.value ? useAsset(imgUrl) : null

const classList = computed(() => {
  const classes = []
  if (props.class) classes.push(props.class)
  if (isLoaded.value && !isHydrating) classes.push('fade-in')

  return classes
})
const imgProps = computed(() => ({ ...attrs, class: ['etg-img transition-opacity', ...classList.value], src: imgUrl.value, alt: props.alt }))

onMounted(() => {
  // SSR 的圖片不會觸發 `onerror`，所以這邊要手動檢查
  if (imgRef.value?.complete && imgRef.value.naturalWidth === 0) {
    onImgLoadError()
  }
})

const showImage = () => {
  if (isPublic.value || isAsset.value || isSvgComponent.value) {
    return
  }

  isLoaded.value = true
}

function onImgLoadError() {
  isFailed.value = true
  emit('error')
}
</script>

<style lang="scss" scoped>
svg:not(:root) {
  width: 100%;
  height: auto;
}

.nd-img-placeholder {
  @apply aspect-1.8 overflow-hidden bg-gray-30;
}

.fade-in {
  animation: fade-in 0.3s ease-in-out;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: unset;
  }
}
</style>
