import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/app/src/middleware/01.auth.global.ts";
export const globalMiddleware = [
  validate,
  _01_45auth_45global
]
export const namedMiddleware = {
  "auth-required": () => import("/app/src/middleware/auth-required.ts"),
  "check-booking-flow": () => import("/app/src/middleware/check-booking-flow.ts"),
  "check-region": () => import("/app/src/middleware/check-region.ts")
}